import { Languages } from './models';

const jp: Languages = {
  common: {
    keyword: 'キーワード',
    here: 'こちら',
    title: 'タイトル',
    description: '概要',
    publicDescription: '公開用の概要',
    requiredInput: '必須',
    createdDate: '作成日',
    lastUpdated: '更新日',
    organization: '組織の変更',
    accountMenu: 'アカウントメニュー',
    notFound: '404 ページが見つかりませんでした',
    sort: '並び替え',
    select: '複数選択',
    select2: '選択する',
    add: '追加',
    createNew: '新規作成',
    save: '保存',
    save2: '保存する',
    saving: '保存中',
    uploading: '保存中',
    edit: '編集する',
    cancel: 'キャンセル',
    ok: 'OK',
    sortLatest: '新着順',
    sortOldest: '投稿順',
    sortASC: 'A - Z',
    sortDESC: 'Z - A',
    createAppointment: '商談を作成',
    startHearing: 'ヒアリング開始',
    infomation: '情報',
    companyName: '企業名',
    companyName2: '会社名',
    name: 'お名前',
    mailAddress: 'メールアドレス',
    phoneNumber: '電話番号',
    externalClientId: '企業ID',
    customerName: 'お客さま名',
    timeTitle: '日時',
    customerTitle: '顧客企業',
    byTitle: '担当営業',
    memoTitle: 'メモ',
    loginTitle: 'Kaizen IDでログイン',
    logoutTitle: 'ログアウト',
    openAccountConsole: 'アカウント管理画面を開く',
    leaveConfirmMessage: '入力した内容は保存されません。画面を閉じてよろしいでしょうか?',
    yesText: 'はい',
    noText: 'いいえ',
    confirmCancel: '本当に中止してよろしいでしょうか？',
    confirmLeave: '移動してもよろしいですか？',
    notSpecified: '設定なし',
    warningUndone: '内容は保存されません',
    emptyMessages: 'アイテムがまだありません',
    emptySearchResult: '検索結果は0件でした',
    emptyData: 'データがまだありません',
    accountSetting: 'アカウント設定',
    profile: 'あなたのプロフィール',
    send: '送信',
    link: 'リンク',
    videoNotavailable: '動画は処理されています。まもなく動画が観覧できます。',
    videoUploadFailed: '動画のアップロードができていないようです。管理者に確認してください。',
    comment: 'コメント',
    csvDownload: 'CSV',
    csvDownloadFailed: 'ダウンロードに失敗しました',
    csvDownloadOption: {
      utf8: 'UTF-8',
      shiftjis: 'SHIFT-JIS',
    },
    reload: 'Reload',
    actionTarget: {
      hearings: 'アンケート',
      products: 'コンテンツ',
      links: '共有用リンク',
      playlists: 'プレイリスト',
    },
    noOrganizationMessage:
      '組織に所属していないため、アクセスできません。組織管理者に招待してもらってください。',
    tooltipText: {
      moveUp: '上へ移動',
      moveDown: '下へ移動',
      delete: '削除する',
      changeFile: 'ファイルを変更する',
      downloadVideo: 'ダウンロード',
      cantChangeFile: 'クリエイターが作成したファイルは置き換えできません',
      edit: '編集する',
    },
    changeOrder: '順序を変更する',
    filter: 'ファイル形式',
    contentLabelText: {
      videos: '動画',
      pdfs: 'PDF',
      images: '画像',
      unknown: 'Unknown',
    },
    preview: 'プレビュー',
    goBack: '戻る',
    notFoundResult: 'ヒアリング結果が取得できません',
    close: '閉じる',
    viewAll: '全てを表示する',
    collapse: '折りたたむ',
    filters: '絞り込み',
    format: 'フォーマット',
    requiredInputError: '{{ target }}を入力してください。',
    inDeveloping: '※この機能は現在開発中です。',
    setting: '設定',
    report: '配信結果',
    search: '検索する',
    search2: '検索',
    download: 'ダウンロード',
    downloading: 'ダウンロード中です',
    downloadFailed: 'ダウンロードに失敗しました',
    downloaded: 'ダウンロードが完了しました',
    copy: 'デフォルトリンクをコピー',
  },
  sidebar: {
    salesContent: '商談利用',
    productContent: 'コンテンツ',
    hearings: 'アンケート',
    salesLog: '商談記録',
    managementTitle: '管理メニュー',
    salesTitle: '活用メニュー',
    hearingLink: '共有用リンク',
    embeddingLink: 'サイト埋め込み',
    setting: '設定',
  },
  salesContent: {
    salesContentTitle: '商談利用',
    hearing: 'ヒアリング',
    hearingSetPlural: 'ヒアリング',
  },
  hearingSet: {
    searchPlaceholder: 'アンケート、商談などを検索', //not in use
    hearingSetTitle: 'ヒアリング',
    questionnaireTitle: 'アンケート',
    hearingItem: {
      numOfVideo: '{{count}} 質問',
    },
  },
  quickHearing: {
    addAppointment: '商談情報を登録',
    viewResult: '結果を見る',
    hubSpotResult: 'HubSpotで見る',
    notFoundAppointment: '商談記録はありません',
  },
  hearingPlay: {
    multipleChoosable: '（複数選択可）',
    previousQuestion: '前の質問',
    nextQuestion: '次の質問',
    endHearing: 'ヒアリング終了',
    endAppointment: '商談を終了',
    end: '終了',
    cancelHearing: 'ヒアリング中止',
    otherAnswerPlaceHolder: 'あなたのコメント',
    result: {
      hearingTitle: 'ヒアリング結果',
      questionnaireTitle: 'アクセス履歴',
      watchTitle: '視聴結果',
      answerLabel: '回答',
      feedbackLabel: '回答に対するフィードバック',
      noteLabel: '備考',
      addHearingSet: 'ヒアリングを追加',
      addProduct: 'コンテンツを追加',
      companyLabel: '企業名 - 任意',
      customerNameLabel: 'お客さま名 - 任意',
      createMemo: 'メモ入力',
      memoLabel: 'メモ',
      namePlaceholder: '名前を入力してください',
      memoPlaceholder: 'メモを入力してください',
      buttonSave: '保存して終了',
      searchHearingPlaceholder: 'ヒアリングを検索',
      endHearing: 'ヒアリング終了',
      otherAnswerLabel: 'その他(自由入力)',
      relatedVideos: '回答からお薦めするコンテンツ',
      registerAfterMeeting: '保存後に登録してください',
      registerAfterMeetingNotice:
        '※企業名登録時は検索結果に他社名も表示されますので、お打ち合わせ終了後に登録してください',
    },
    messages: {
      createQuickHearingSuccess: '商談情報が作成されました',
    },
    noneDataMessage: 'この質問にはコンテンツが添付されていません。',
    backToResult: 'ヒアリング結果に戻る',
    backToSalesContent: 'コンテンツ一覧に戻る',
    recording: '商談記録中',
    alreadyAnswered: '回答済み',
    confirmLeaveHearing: 'すべての回答は破棄されます。移動してもよろしいでしょうか？',
    cancelRecording: '商談記録をキャンセルしました。',
  },
  productContent: {
    title: 'コンテンツ',
    startAppointment: '閲覧する',
    createDate: '更新日',
    otherContent: 'その他のコンテンツ',
    searchContentPlaceholder: 'コンテンツの検索',
    showMoreContents: '全てのコンテンツをみる',
    showMoreQuestionnaires: '全てのアンケートをみる',
    filterType: {
      all: 'すべて',
      video: '動画',
      other: '資料',
    },
    allTags: 'すべて',
  },
  createAppointment: {
    messages: {
      createAppointmentSuccess: '商談情報が作成されました',
    },
    errors: {
      companyName: '企業名は必須項目です',
      clientId: '企業名は必須項目です',
    },
  },
  appointmentDetail: {
    title: '商談詳細',
    deleteSuccess: '商談情報が削除されました',
    updateSuccess: '商談情報が更新されました',
  },
  sharingLink: {
    viewArchivedList: 'アーカイブのみ',
    searchLinks: '共有用リンクを検索する',
    screenTitle: '共有用リンク',
    linkCount: 'リンク数',
    viewCount: '閲覧数',
    addHearingLink: 'リンク作成',
    disabledCreateLinkTooltip: 'この{{type}}を公開状態にすると共有用リンクを作成できます。',
    addLinkDrawerTitle: '共有用リンク作成',
    accountLabel: '顧客企業',
    titleAndAccountLabel: 'タイトル / 顧客企業',
    noSpecifiedAccount: '顧客企業なし',
    createNewOption: '新規追加',
    createdByLabel: '作成者',
    clientCommentTitle: 'コメント、ご要望など',
    clientCommentPlaceHolder: '例) サービスについて詳しく知りたいので、打ち合わせを希望したい',
    messages: {
      createHearingLinkFail: '共有用リンクの作成が失敗しました',
      createHearingLinkSuccess: '新しい共有用リンクが作成されました',
      updateHearingLinkSuccess: '共有用リンクが更新されました',
      updateHearingLinkFail: '共有用リンクの更新に失敗しました',
      notFoundHearingLink: '存在しない共有用リンクです',
    },
    copySuccess: 'コピーしました',
    copyFail: 'コピーが失敗しました',
    copyDefault: 'リンクをコピーする',
    noPublicHearing: '公開されているアンケートがありません。公開してから、ONにしてください',
    noPublicProductContent: '公開されているコンテンツがありません。公開してから、ONにしてください',
    noPublicPlaylist: '公開されているプレイリストがありません。公開してから、ONにしてください',
    urlParameters: 'URLパラメータ',
    selectAll: '全て選択',
    releaseAll: '全て解除',
    filter: {
      all: '全て',
      mine: '自分のリンクのみ',
    },
    previewTooltip: 'プレビューは閲覧・回答データが送信されません。設定内容の確認に利用できます。',
    viewSetting: {
      title: '閲覧時の表示設定',
      beforeViewing: '閲覧前',
      afterViewing: '閲覧完了後',
      showCustomForm: 'フォームを表示する',
      subShowFormLabel:
        'チェックをつけると、閲覧ユーザーを識別するためのフォーム入力が必須になります。',
      noCustomFieldSelected: 'フォームの項目を選択してください',
      showResult: '結果画面を表示する',
      subShowResultLabel:
        'アンケート結果・フィードバックを表示します。ユーザーからのコメントも受け付けられます。',
      ctaSetting: {
        defaultLabel: 'お問い合わせ',
        subSectionTitle: 'CTA',
        autoRedirect: '自動リダイレクトする',
        noCTAButton: 'CTAは設定されていません。',
        addCTAButton: '追加する',
        guideText: '(※CTAを1つだけ設定した場合に選択できます)',
        invalidUrl: '無効なURLです',
        requireUrl: 'URLを設定してください',
        addCTA: 'CTAを追加',
        editCTA: 'CTAを編集',
        dislayText: '表示テキスト',
        url: 'URL',
        displayTextPlaceHodler: '例）お問い合わせ',
        urlPlaceHolder: 'URLを入力',
      },
    },
    addTitleAndContent: 'タイトルとコンテンツの選択',
    linkType: 'リンクのタイプ',
    noAdministrativeTitle: 'タイトルがありません',
    accessTimeLabel: 'アクセス時間',
    switchLink: 'リンクをON/OFFする',
    administrationSetting: {
      drawerTitle: '概要の入力',
      titleLabel: '管理用タイトル',
      memoLabel: 'メモ',
      subMemoLabel: '閲覧ユーザーには表示されません。',
      subTitleLabel: '閲覧ユーザーには表示されません。',
      clientAccountLabel: '顧客企業を設定する',
      subClientAccountLabel:
        '閲覧ユーザーを企業毎に識別・グループ化するために使用します。誤送信防止のため、リンク作成後は変更できません。',
    },
    selectType: {
      productContents: {
        label: 'コンテンツ',
        tooltip:
          '1ページごとに1つのコンテンツを表示します。 コンテンツをじっくり視聴してもらうのに効果的です。',
      },
      questionnaire: {
        label: 'アンケート',
        tooltip:
          'コンテンツごとにアンケートを表示し、ビジネスに関する視聴者のデータを得ることができます。',
      },
      playlist: {
        label: 'プレイリスト',
        tooltip:
          '複数のコンテンツの視聴ボタンを常に表示して視聴コンテンツの切り替えを容易にします。',
      },
    },
    selectFile: 'ファイルを選択する',
    selectPlaylist: 'プレイリストを選択する',
    selectQuestionnaire: 'アンケートを選択する',
    linkContentType: {
      productContents: 'コンテンツ',
      questionnaire: 'アンケート',
      playlist: 'プレイリスト',
    },
    resultModal: {
      openContentDetail: 'コンテンツ詳細を開く',
      openHearingDetail: 'アンケート詳細を開く',
      questionHasNoAnswer: '質問はまだ回答されていません。',
    },
    getViewingLogsError: '視聴ログを取得できません。',
    noViewingLogs: '視聴履歴がまだありません。',
    disableCreateLink: 'この機能はご契約手続きが必要です。問い合わせは',
    startTimeSetting: {
      setStartTimeButton: '時間を指定してコピー',
      modalTitle: '時間を指定してコピーする',
      copyButton: 'コピーする',
      startTimeInputLabel: '開始位置',
      invalidTimeError: '動画長を超えない開始位置を指定してください。',
    },
    selectFileModalTitle: 'ファイルを選択',
    selectPlaylistModalTitle: 'プレイリストを選択',
    selectQuestionnaireModalTitle: 'アンケートを選択',
  },
  embeddingLink: {
    embeddingLink: 'サイト埋め込み',
    createEmbeddingLink: '動画枠を作成',
    infoSetting: {
      title: 'タイトルと概要',
      infoInput: '概要の入力',
    },
    controlOption: {
      showPlaybarControl: '再生バー',
      showVolumeControl: '音量',
      showCenterPlayButton: '中央の再生ボタン',
      enabledFullscreen: '全画面',
      volumeControlTooltip:
        '「ミュート」のチェックを外すと、音量コントロールの表示は必須になります。',
    },
    endScreenType: {
      guide: '中央の再生ボタンまたは再生バーをONにすると、自動ループ以外を選択できます。',
      thumbnail: 'サムネイル',
      loop: '自動ループ',
    },
    playbackSetting: {
      title: '再生設定',
      control: 'コントロールの表示',
      endScreen: '終了画面',
      startTimeSettings: '再生開始時の挙動',
    },
    startTimeSettings: {
      autoplay: '自動再生',
      muted: 'ミュート',
      mutedTooltip:
        '主要モバイルブラウザでは音量ON&自動再生は禁止されているため、自動再生の場合「ミュート」がオフでも音量は出ません。\nまた、PCデバイスでもユーザーの操作がない場合は音量ONでは自動再生されないことがあります。そのため、自動再生時はミュートを推奨しています。',
      autoplayTooltip:
        '自動再生をオフにするには、ユーザーが動画を再生できるよう「中央の再生ボタン」または「再生バー」にチェックをつけて下さい。',
    },
    embedCode: '埋め込みコード',
    embedCodeCloud: 'サイト改善クラウド',
    embedCodeCloudDescription1: 'エクスペリエンススクリプトに記載して下さい',
    embedCodeCloudDescription2: 'デザイン案に記載して下さい',
    embedCodeHTML: 'HTML',
    embedCodeHTMLDescription1: '<head/>の直前に記載してください',
    embedCodeHTMLDescription2: '<body>内の動画を配置したい箇所に記載してください',
    experienceScriptLink: 'エクスペリエンススクリプトとは',
    showEmbedCode: '埋め込みコードを表示する',
    copyCode: '埋め込みコードをコピーする',
    copySuccess: 'コードをコピーしました',
    copyFail: 'コードをコピーできませんでした',
    embedVideo: '埋め込む動画',
    selectEmbedVideoGuide: '埋め込む動画を選択しましょう',
    selectVideo: '動画を選択する',
    edit: '編集する',
    contentType: {
      embeddingVideo: 'シンプル動画',
      interactiveVideo: 'インタラクティブ動画',
    },
    noVideoSelected: '動画未選択',
    title: 'サイト埋め込み',
    addEmbeddingLink: '埋め込みコードを新規作成する',
    linkListTitle: '埋め込みコード',
    createNewFrame: '動画枠を作成',
    messages: {
      createSuccess: '新しい埋め込み枠が作成されました',
      createFail: '埋め込み枠の作成が失敗しました',
      updateSuccess: '埋め込み枠が更新されました',
      updateFail: '埋め込み枠の更新が失敗しました',
      linkNotFound: 'アイテムが存在しません',
      getInteractiveHearingError: 'インタラクティブ動画を取得できません',
      getViewRateError: '視聴達成率を取得できません',
    },
    selectType: {
      title: 'フォーマット',
      simpleVideo: {
        label: 'シンプル',
        tooltip: '動画を一つだけ埋込みできます。トップページやLPの訴求に効果的です。',
      },
      questionnaireVideo: {
        label: 'アンケート動画',
        tooltip:
          '動画を見ながらアンケートに回答してもらいます。ニーズ確認や問い合わせの満足度調査などに効果的です。',
      },
      interactiveVideo: {
        label: 'インタラクティブ動画',
        tooltip: '選択肢によって動画の内容が変化し、インタラクティブな体験を提供できます。',
      },
    },
    selectEmbedVideo: {
      title: '埋め込み動画の選択',
      guide: '公開されているコンテンツのみ表示されます',
    },
    interactiveVideoSection: {
      selectVideo: 'インタラクティブ動画を選択する',
      changeVideo: 'インタラクティブ動画を変更する',
      drawerTitle: 'インタラクティブ動画の選択',
      guide: '回答データの収集を正確におこなうため、作成後はアンケートの変更はできません。',
    },
    reportTab: {
      noViewRate: {
        mainLabel: '動画がまだ視聴されていないようです',
        subLabel: 'データ反映に数十分かかる可能性があります',
      },
      noVideo: '埋め込み動画がまだ選択されていないようです',
      answerCount: '回答数',
      other: 'その他',
    },
  },
  modalhearing: {
    questionnaire: 'アンケート',
    addQuestionnaire: 'アンケートを追加',
    searchQuestionnaire: 'アンケートを検索する',
  },
  usingServiceInquiry: {
    movieUrl:
      'https://files.kaizenplatform.net/encoded/1494/0/4c0cc0faf9579a4b6338c6256f410f12c9b826667de0cce411f10f07a3b25dc1/abda7aa9-c4f1-4a79-ba98-d7b260878cd5_orig.m3u8?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImM4NGZjOWMyNzFmNGYwODY1OTJkZTE5YmQ1MWViZGM2OGQ0YWNhYzM4MGY4ODdjMmRkMDlkOGYyMjkyM2ViZGIxZGViYmFlYzYxZTQ4ZmEwMGJjYzBjZTRhMGZjN2I4ODgzYmExOTJjOWU1NjU4YWFiNTFjZDlmZTNmY2ZiOGU2YTlhYWYzM2RlYmNhMWE2OGM4N2I4MDRiYjc4NmQxYjVkNzkwZjYzNjVmMGRhM2Y5YTc3Nzg3YjVlNjk2NDBkYTdkZmI2ZmIwZTcwM2I0YWEzMDI2NjVkYzFlMmU0ZjYyYjg4M2I5OTdiNTY0MWVkZjIxNmQ4MDlkMmZjOWE4ODMyODE5MWUyZWUyMDJiNDJhZDA1YTdiNzA5MDc1OWRiZDcwYjAwYmJhODEwNGE0NTJjMzEyZWYzNjc3Zjg3NmY4YTU3NmZiZTZjMjliODQ3ZWZkMmVlNDc0OTRhYjgzNDg4YzRkMTA2NzgxODkyNDFmODI4ZjZjOGFlOTk0MGU1MmEzYTExYjNjMTBmYTAxIn0.TFQic_cEwlYshgXGGczh4OsJf6kuiDTWT6A8RFfGv60', //2100までのtokenを付与している
    text: 'KAIZEN Salesは、動画・データでセールス ＆ マーケティング領域の DX を加速します',
    button: 'ご利用のお問い合わせはこちら',
    href: 'https://kaizenplatform.com/lp/sales/',
    notice: 'この機能をご利用いただくには、ご契約手続きが必要です。お問い合わせはこちら',
  },
  salesLog: {
    title: '商談記録',
    tabName: {
      quickHearing: '一時保存',
      appointment: '完了',
    },
  },
  admin: {
    common: {
      viewArchivedList: 'アーカイブを表示',
      filename: 'ファイル名',
      uploadProductContent: 'コンテンツをアップロード',
      uploadThumbnail: 'サムネイルをアップロード',
      uploadFileButtonLabel: 'コンテンツを再アップロード',
      dropzoneGuide: 'クリックして選択、またはここにファイルをアップロード',
      uploadThumbnailButtonLabel: 'サムネイルを再アップロード',
      content: 'コンテンツ',
      draft: '下書き',
      publish: '公開',
      archive: 'アーカイブ',
      setting: '設定',
      restore: '元に戻す',
      dropzoneMainGuide: '{{ type }}ファイルをアップロード',
      browse: 'ファイルを選択',
      thumbnail: 'サムネイル',
      title: 'タイトル',
      checkbox: 'チェックボックス',
      radio: 'ラジオボタン',
      delete: '削除',
      productTitlePlaceholder: 'タイトルを入力してください',
      description: '概要',
      productDescriptionPlaceholder: '紹介文を記入してください',
      confirmArchive: 'アーカイブ',
      confirmDelete: '削除する',
      messageConfirmArchive: '{{ item }}をアーカイブしますか？',
      messageConfirmDelete: 'データを削除しますか？',
      cancelArchive: 'いいえ',
      messages: {
        archiveSuccess: '{{ item }}がアーカイブされました',
        restoreSuccess: '{{ item }}が元に戻されました',
        deleteSuccess: 'データが削除されました',
        fileExtensionError: '有効な拡張子は{{ validExtension }}です。',
        cannotViewPlaylistDetail: 'アーカイブされたプレイリストは開けません。',
        updateStatusFailed: 'このコンテンツは既に利用されているため下書きに戻せません。',
      },
      uploadingProgress: 'ファイルをアップロードしています...',
      uploadFailed: 'アップロードが失敗しました:',
      notLoadThumbnail:
        '{{filename}}のサムネイルはロードできません。画像をアップロードして、サムネイルを設定してください',
      exceedMaxSize:
        'サイズの上限を超えています。{{ sizeLimit }}MB 以下のファイルを選んでください。',
      loadingThumbnail: 'サムネイル読み込み中...',
      notViewDetail: '削除されましたファイルは開けません',
      notFileVideo: '{{filename}}: 動画のファイル形式はサポートされません',
      notFileImage: 'サムネイルのファイル形式はサポートされません',
      notSupportedCodec:
        'このファイルは、このブラウザでサポートしていない動画コーデックが利用されてます。 H.264のコーデックの動画をご利用ください。',
      isDraftOrArchived: 'ステータスが下書きまたはアーカイブとなっています',
      itemInUse: 'リンクに紐付いたコンテンツ',
      dropzoneTarget: {
        videos: '動画',
        images: '画像',
        pdfs: 'PDF',
        none: '',
        videos_images_pdfs: '動画、画像、PDF',
      },
      dropzoneOptionDivider: 'または',
      changeToPublic: '公開する',
      changeToDraft: '下書きにする',
    },
    productContent: {
      titleHeader: 'コンテンツの管理',
      upload: 'アップロード',
      emptyProduct: 'コンテンツは0件です',
      messages: {
        changeStatusSuccess: 'ステータスが更新されました',
        createSuccess: 'コンテンツが作成されました',
        updateProductSuccess: 'コンテンツが更新されました',
        updateProductFail: 'コンテンツが更新できませんでした',
        notFoundProduct: 'コンテンツが存在しません!',
        thumbnailResolutionError:
          'サムネイルをアップロードする時、幅は{{maxWidth}}px以下、高さは{{maxHeight}}px以下の画像を選択してください。',
        confirmChangeFile1: 'このコンテンツが利用されているアンケートでも、',
        confirmChangeFile2: '表示されるファイルが置き換わります。',
        getHearingSetsError: '関連のアンケートを取得できませんでした',
        cannotChangeContentType: 'ファイルの形式は後に変更できません。',
        chapterTimeExceedMaxSize: '動画の長さを超えた位置にチャプターを設定できません。',
      },
      titleBlank: 'タイトルは必須項目です',
      descriptionBlank: '紹介文は必須項目です',
      contentInUse:
        'このコンテンツはリンクに紐付いています。リンクで見れなくなりますが、よろしいですか？',
      emptyState: '動画をアップロードするか、または動画制作を依頼しましょう',
      orderVideo: '動画制作を依頼する',
      orderVideoHref: 'https://cloud.kaizenplatform.net/ad/latest-insights',
      changeFileComfirm: 'ファイル置き換えに進む',
      changeFile: 'ファイルを置き換える',
      hearingSetTitleHearder: 'このコンテンツが使われているアンケート',
      chapterSection: {
        title: 'チャプター',
        noChapter: 'チャプターは設定されていません。',
        addChapter: 'チャプターを追加',
        maximumNumberOfChapters: 'チャプターを20個まで設定可能です。',
        blankInputError: 'チャプター名は必須項目です。',
      },
    },
    hearingSet: {
      titleHeader: 'アンケート管理',
      addHearing: 'アンケート作成',
      placeholderTitleHearing: 'アンケートのタイトルを入力してください',
      addQuestions: '質問を追加する',
      noTitle: 'タイトル未設定',
      messages: {
        createHearingSuccess: '新しいアンケートが作成されました！',
        createHearingError: 'アンケートの作成時にエラーが発生しました!',
        updateHearingSuccess: 'アンケートを更新しました!',
        updateHearingFail: 'アンケートの更新時にエラーが発生しました!',
        titleBlank: 'アンケートのタイトルは必項です',
        questionBlank: '質問を追加して下さい。',
        noResults: '検索結果はありません',
        notFoundHearing: 'アンケートは存在しません!',
        statusValidationError: '質問が存在しないアンケートは公開できません',
      },
      hearingSetInUse:
        'このアンケートはリンクに紐付いています。リンクで見れなくなりますが、よろしいですか？',
      questionIndex: 'No.{{ index }}',
      saveQuestionOrder: '質問の順序を保存',
      editQuestionOrder: '質問の順序を変更する',
      editQuestionOrderHint: 'ドラッグ＆ドロップで質問の順序を変更できます',
      instanceType: {
        contentAndQuestionnaire: {
          label: 'コンテンツ + アンケート',
          tooltip: 'コンテンツの下に質問と回答を表示するフォーマットです。',
        },
        interactiveVideo: {
          label: 'インタラクティブ動画',
          tooltip:
            '動画上に選択肢をオーバーレイします。選択肢によって次動画の内容が分岐し、インタラクティブな体験を提供できます。',
        },
      },
      disabledPreviewTooltip: '質問を1つ以上設定するとプレビュー表示してご確認いただけます。',
    },
    questions: {
      deleteQuestion: '質問を削除',
      addQuestion: '質問を作成',
      editQuestion: '質問の編集',
      submittingAddQuestion: '質問を作成中',
      savingUpdateQuestion: '保存中',
      question: '質問',
      content: 'コンテンツ',
      placeholderQuestion: '質問を入力してください',
      placeholderFeedback: '回答に対するフィードバック',
      placeholderAnswer: '回答 {{index}}',
      addAnswer: '回答を追加',
      countAnswers: '{{count}} 回答',
      createSuccess: '質問が追加されました',
      contentFormLabel: {
        videos: '動画をアップロード',
        links: '動画リンク',
        images: '画像',
        pdfs: 'PDF',
        existing_contents: '既存のコンテンツから選択',
        none_data: 'なし',
        videos_images_pdfs: 'ファイルアップロード',
      },
      embedURL: '動画URL',
      embedVideoLink: '動画リンクを入力',
      embedModalLabel: 'YouTube・Vimeo の動画URLを入力してください',
      videoURL: '動画URL',
      changeLink: '動画リンクを変更',
      addOther: '「その他」を選択肢に追加する',
      errors: {
        invalidVideoURL: 'YouTubeもしくはVimeoの動画URLを入力してください',
        notFound: '入力されたURLの動画が見つかりませんでした',
      },
      messages: {
        questionBlank: '質問タイトルは必項です',
        answerBlank: '回答は必項です',
        createQuestionSuccess: '質問が作成されました',
        createQuestionError: '質問が作成できませんでした',
        updateQuestionSuccess: '質問が更新されました',
        updatedQuestionError: '質問が更新できませんでした',
        deleteQuestionSuccess: '質問が削除されました',
        deleteQuestionError: '質問が削除できませんでした',
        updateContentCaution1: 'アップロードしたファイルは、この質問にのみ利用されます。',
        updateContentCaution2:
          '他の質問やコンテンツとして使いたい場合は、コンテンツ一覧からアップロードしてください。',
      },
      maximumNumberOfQuestion: '質問を10個まで設定可能です',
      maximumNumberOfAnswer: '回答を10個まで設定可能です',
    },
    setting: {
      tabs: {
        personal: 'ユーザー設定',
        organization: '組織設定',
      },
      salesGuideText: '組織設定を変更する権限がありません。',
      changeLogo: {
        title: 'ロゴの変更',
        button: '変更する',
        currentLogo: '現在のロゴ',
        newLogo: '新しいロゴ',
        uploadLogoAcceptedFormats: '受け付けるフォーマット: jpeg, png, gif',
        uploadLogoMaxSize: '最大1MB',
        messages: {
          changeLogoSuccess: 'ロゴが変更されました',
          changeLogoError: 'ロゴが変更できませんでした',
        },
        guideText: '共有用リンクのヘッダーに表示するロゴを変更できます',
      },
      slack: {
        title: 'Slack連携',
        link: '連携する',
        linked: '連携済みです',
        unlink: '接続解除',
        guideText: '共有用リンクが閲覧された時に、slackへ通知することができます',
        drawer: {
          title: 'Slack 連携設定',
          selectChannelGuide: '通知するチャンネルを選択して下さい',
        },
        messages: {
          assignSuccess: '通信が成功しました',
          success: 'Slack 連携が完了しました',
          deleteSuccess: 'Slack 連携の削除が成功しました',
          slackIntegrationError: 'Slack連携ができませんでした',
        },
        connectFailed: 'Slack連携に失敗しました。もう一度連携し直して下さい。',
        connection: {
          selectedWorkspace: 'ワークスペース{{workspace}}に連携済です',
          selectedChannel: '通知先 #{{channel}}',
          channelUnselected: 'チャンネル名未設定',
        },
      },
      hubSpot: {
        title: 'HubSpot連携',
        link: '連携する',
        linked: '連携済',
        unlink: '連携を解除する',
      },
    },
    previewHearing: {
      endPreview: 'プレビューを終了',
      noQuestion: 'ヒアリングに質問が1件もありません',
    },
    content: {
      listPageTitle: 'コンテンツ',
      tabName: {
        files: 'ファイル',
        playlists: 'プレイリスト',
        questionnaires: 'アンケート',
      },
    },
    playlist: {
      searchPlaceHolder: 'プレイリストを検索する',
      createPlaylistDrawer: {
        title: 'プレイリスト作成',
        selectContents: 'コンテンツを追加する',
        guideText:
          '閲覧データの取集を正確におこなうため、プレイリスト作成後はコンテンツの差し替えはできません。（並べ替えは作成後も可能です）',
        playlistTitleLabel: '管理用タイトル',
        playlistTitleSubLabel: '閲覧ユーザーに表示されません。',
        playlistMemoLabel: 'メモ',
        playlistMemoSubLabel: '閲覧ユーザーに表示されません。',
        loadMoreContents: 'さらに読み込む',
      },
      messages: {
        createPlaylistSuccess: '新しいプレイリストが作成されました',
        createPlaylistFailed: 'プレイリストの作成が失敗しました',
        getPlaylistListFailed: 'プレイリスト一覧が取得できません',
        getPlaylistFailed: 'プレイリストが取得できません',
        updatePlaylistSuccess: 'プレイリストが更新されました',
        updatePlaylistFailed: 'プレイリストの更新に失敗しました',
      },
      contentCount: '{{count}} ファイル',
      playlistSetting: {
        changeDisplayText: '表示テキストを設定する',
        displayedText: '表示テキスト',
        drawerTitle: 'コンテンツ編集',
        instruction:
          'プレイリストのボタンに表示するテキストを任意で変更できます。\n※使用コンテンツのタイトルが自動で登録されています。この表示テキストを変更しても、使用コンテンツのタイトルには影響しません。',
        displayedTextBlank: '表示テクストは必須項目です。',
      },
    },
  },
  creativeSearch: {
    contentType: {
      video: '動画',
      image: '画像',
      pdf: 'PDF',
      others: 'その他',
    },
    fileType: 'ファイル形式',
    select: '選択',
    selectAnotherContent: '他のコンテンツを選ぶ',
  },
};

export default jp;
