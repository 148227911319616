import { useOrganization } from 'organisms/useOrganization';
import { useLocationSearch, usePushLocationSearch, useStore } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Content } from 'types/App';
import { HearingLink, ProductForLink } from 'types/HearingLink';
import { hearingLinkStatus, routes } from 'utility/constants';
import getHearingWebclientPath from 'utility/getHearingWebclientPath';
import { MenuItem } from 'views/components/compound/MoreActionsMenu';
import { defaultListMeta } from 'stores/HearingLinkStore/constants';

type TabItem = {
  id: number;
  tabName: 'files' | 'playlists' | 'questionnaires';
  linkType: 'product_contents' | 'playlist' | 'hearing_set';
};

export const TAB_LIST: TabItem[] = [
  {
    id: 0,
    tabName: 'files',
    linkType: 'product_contents',
  },
  {
    id: 1,
    tabName: 'playlists',
    linkType: 'playlist',
  },
  {
    id: 2,
    tabName: 'questionnaires',
    linkType: 'hearing_set',
  },
];

export const useHearingLink = () => {
  const {
    hearingLinkStore: { hearingLinkDetail },
    appStore: {
      userData: { id: userId },
    },
  } = useStore();
  const { id: linkId, session_visit_id } = useParams<{ id: string; session_visit_id: string }>();
  const sessionVisitId = session_visit_id ? parseInt(session_visit_id) : undefined;
  const { user_id } = hearingLinkDetail;
  const isLinkCreator = useMemo(() => user_id?.toString() === userId, [user_id, userId]);
  const { status, content_type } = useMemo(() => hearingLinkDetail, [hearingLinkDetail]);
  const isArchived = status === hearingLinkStatus.archived;
  const history = useHistory();
  const handleGoBack = () => {
    const tabName =
      content_type === 'hearing_set'
        ? 'questionnaires'
        : content_type === 'playlist'
        ? 'playlists'
        : 'files';
    const goBackTarget = isArchived
      ? `${routes.hearingLink}?isArchived=1`
      : `${routes.hearingLink}?tab=${tabName}`;
    history.push(goBackTarget);
  };

  return {
    sessionVisitId,
    linkId,
    isLinkCreator,
    isArchived,
    handleGoBack,
  };
};

//For managing action in link detail page
export const useHearingLinkHeader = () => {
  const { t } = useTranslation();
  const { organizationId } = useOrganization();
  const {
    hearingLinkStore: { hearingLinkDetail, updateStatusHearingLink, restoreHearingLink },
  } = useStore();

  const { linkId, isArchived } = useHearingLink();

  const { status, hash_token } = hearingLinkDetail;

  const linkDetailURL = useMemo(() => {
    const hearingWebclientPath = getHearingWebclientPath(organizationId);
    return `${hearingWebclientPath}${routes.clientStartHearingLink}/${hash_token}`;
  }, [hash_token, organizationId]);

  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false);
  const [isStartTimeSettingDrawerOpen, setStartTimeSettingDrawerOpen] = useState(false);

  //Detect targetVideo to apply start time to
  //It will be: content video of link type product_contents of the first video content of link type playlist / hearing_set
  const targetVideo = useMemo(() => getFirstVideoOfLink(hearingLinkDetail), [hearingLinkDetail]);

  const toggleArchiveModal = () => {
    setArchiveModalOpen(!isArchiveModalOpen);
  };

  const toggleStartTimeSettingDrawer = () =>
    setStartTimeSettingDrawerOpen(!isStartTimeSettingDrawerOpen);

  const handleArchiveHearingLink = () => {
    updateStatusHearingLink(parseInt(linkId), hearingLinkStatus.archived);
  };

  const handleRestoreLink = () => {
    restoreHearingLink(parseInt(linkId), true);
  };

  const switchLinkActiveStatus = () => {
    updateStatusHearingLink(parseInt(linkId), status === 'active' ? 'inactive' : 'active');
  };

  const moreActionMenu: MenuItem[] = isArchived
    ? [{ label: t('admin.common.restore'), handleClick: handleRestoreLink }]
    : [{ label: t('admin.common.archive'), handleClick: toggleArchiveModal }];

  return {
    handleArchiveHearingLink,
    moreActionMenu,
    switchLinkActiveStatus,
    isArchiveModalOpen,
    toggleArchiveModal,
    linkDetailURL,
    isStartTimeSettingDrawerOpen,
    toggleStartTimeSettingDrawer,
    targetVideo,
  };
};

//Get link list and handle switch tab
export const useLinkListByContent = () => {
  const {
    hearingLinkStore: { getLinkListByContent, resetHearingLinkList },
  } = useStore();
  const { page, tab, user_id } = useLocationSearch();
  const pushLocation = usePushLocationSearch();

  const currentTab = TAB_LIST.find(tabItem => tabItem.tabName === tab);
  const [activeTabIndex, setActiveTabIndex] = useState(currentTab?.id || 0);
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleSetSearchKeyword = (key: string) => setSearchKeyword(key);

  useEffect(() => {
    const pageNum = typeof page === 'string' ? parseInt(page) : defaultListMeta.page;
    const userId = typeof user_id === 'string' ? user_id : undefined;
    if (tab === 'files' || !tab) {
      getLinkListByContent({
        page: pageNum,
        content_type: 'product_contents',
        user_id: userId,
        key: searchKeyword,
      });
    }
    if (tab === 'playlists') {
      getLinkListByContent({
        page: pageNum,
        content_type: 'playlist',
        user_id: userId,
        key: searchKeyword,
      });
    }
    if (tab === 'questionnaires') {
      getLinkListByContent({
        page: pageNum,
        content_type: 'hearing_set',
        user_id: userId,
        key: searchKeyword,
      });
    }
    return () => {
      resetHearingLinkList();
    };
  }, [page, tab, user_id]);

  useEffect(() => {
    if (tab && !currentTab) {
      pushLocation({
        tab: TAB_LIST[0].tabName,
        page: undefined,
      });
      return;
    }

    setActiveTabIndex(currentTab?.id || 0);
  }, [tab]);

  const onChangeTab = (nextTabIndex: number) => {
    if (nextTabIndex !== activeTabIndex) {
      pushLocation({
        tab: TAB_LIST[nextTabIndex].tabName,
        page: undefined,
      });
      setSearchKeyword('');
    }
  };

  const handleSearchLinks = () => {
    const pageNum = typeof page === 'string' ? parseInt(page) : defaultListMeta.page;
    const userId = typeof user_id === 'string' ? user_id : undefined;
    getLinkListByContent({
      page: pageNum,
      content_type:
        tab === 'playlists'
          ? 'playlist'
          : tab === 'questionnaires'
          ? 'hearing_set'
          : 'product_contents',
      user_id: userId,
      key: searchKeyword,
    });
  };

  return {
    page,
    activeTabIndex,
    onChangeTab,
    searchKeyword,
    handleSetSearchKeyword,
    handleSearchLinks,
  };
};

const getFirstVideoOfLink = (hearingLinkDetail: HearingLink): Content | ProductForLink | null => {
  const { content_type, hearing_set, product_contents, content_playlist } = hearingLinkDetail;
  switch (content_type) {
    case 'hearing_set':
      const questionSetWithVideoContent = hearing_set.question_sets.find(
        qs => qs.content?.content_type === 'videos'
      );
      return !!questionSetWithVideoContent ? questionSetWithVideoContent.content : null;
    case 'product_contents':
      return product_contents.find(content => content.content_type === 'videos') || null;
    case 'playlist':
      return (
        content_playlist?.product_contents.find(content => content.content_type === 'videos') ||
        null
      );
    default:
      return null;
  }
};
